import { Suspense, lazy } from 'react';
import ScrollToTop from './helpers/scroll-top';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

const HomePage = lazy(() => import('./pages/home/index'));
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const Collection = lazy(() => import('./pages/shop/index'));
const Cart = lazy(() => import('./pages/user/Cart'));
const WishList = lazy(() => import('./pages/user/Wishlist'));
const Compare = lazy(() => import('./pages/user/Compare'));
const MyAccount = lazy(() => import('./pages/auth/MyAccount'));
const Checkout = lazy(() => import('./pages/user/Checkout'));
const Product = lazy(() => import('./pages/shop-product/Product'));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }>
          <Routes>
            <Route path={process.env.PUBLIC_URL + '/'} element={<HomePage />} />
            <Route path={process.env.PUBLIC_URL + '/login'} element={<Login />} />
            <Route path={process.env.PUBLIC_URL + '/register'} element={<Register />} />
            <Route path={process.env.PUBLIC_URL + '/collection'} element={<Collection />} />
            <Route path={process.env.PUBLIC_URL + '/cart'} element={<Cart />} />
            <Route path={process.env.PUBLIC_URL + '/wishlist'} element={<WishList />} />
            <Route path={process.env.PUBLIC_URL + '/compare'} element={<Compare />} />
            <Route path={process.env.PUBLIC_URL + '/checkout'} element={<Checkout />} />
            <Route path={process.env.PUBLIC_URL + '/product/:id'} element={<Product />} />
            <Route path={process.env.PUBLIC_URL + '/my-account'} element={<MyAccount />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
