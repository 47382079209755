import { createContext, useEffect, useReducer } from 'react';
import axios from '../utils/axios';
import { Spinner } from 'react-bootstrap';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated
      };
    }

    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    }

    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  logout: () => {},
  // eslint-disable-next-line no-unused-vars
  login: (email, password, role) => Promise.resolve()
});

// eslint-disable-next-line react/prop-types
export const JWTAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password, role) => {
    const { data } = await axios.post('/auth', {
      email,
      password,
      role
    });
    setSession(data.token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: data.user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT'
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          setSession(accessToken);
          const { data } = await axios.get('/secured/users/me');
          dispatch({
            type: 'INIT',
            payload: { user: data, isAuthenticated: true }
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: { user: null, isAuthenticated: false }
          });
        }
      } catch (err) {
        logout();
      }
    })();
  }, []); // show loading until not initialized

  if (!state.isInitialized) <Spinner animation="grow" />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
