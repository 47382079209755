import axios from 'axios';
import { serverConfig } from '../config';

const axiosInstance = axios.create({
  baseURL: serverConfig.serverUrl
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
export default axiosInstance;
