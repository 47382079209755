import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  loading: false,
  list: {
    isLoading: false,
    records: [],
    filters: {},
    currentPage: 1,
    searchText: '',
    totalRecords: 0,
    totalRecordsQueried: 0
  },
  selectedProduct: {
    isLoading: false,
    data: {}
  },
  error: false,
  errorMessage: ''
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductsAsync.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(getAllProductsAsync.fulfilled, (state, action) => {
        state.list.isLoading = false;
        state.list.records = action.payload.records;
        state.list.totalRecords = action.payload.totalRecords;
        state.list.totalRecordsQueried = action.payload.totalRecordsQueried;
      })
      .addCase(getAllProductsAsync.rejected, (state, action) => {
        state.list.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      })
      .addCase(getProductDetailsAsync.pending, (state) => {
        state.selectedProduct.isLoading = true;
      })
      .addCase(getProductDetailsAsync.fulfilled, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.selectedProduct.data = action.payload.data;
      })
      .addCase(getProductDetailsAsync.rejected, (state, action) => {
        state.selectedProduct.isLoading = false;
        state.error = true;
        state.errorMessage = action;
      });
  }
});

export const { setProducts } = productSlice.actions;

export const getAllProductsAsync = createAsyncThunk('getAllProductsAsync', async (args) => {
  const response = await axiosInstance.get('api/products/all' + args.queryString);
  return response.data;
});

export const getProductDetailsAsync = createAsyncThunk('getProductDetailsAsync', async (args) => {
  const response = await axiosInstance.get(`api/products/${args.id}`);
  return response.data;
});

export default productSlice.reducer;
